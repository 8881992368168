import React, { useState } from "react"
import jsonp from "jsonp"

const NewsletterBox = () => {
  const [email, setEmail] = useState("")
  const [formStatus, setFormStatus] = useState({})

  const handleSubmit = e => {
    e.preventDefault()

    const strUrl =
      "https://iqaraislam.us4.list-manage.com/subscribe/post-json?u=f280adb069e2e4076ecd996d2&id=487f7a4509"
    const params = `EMAIL=${encodeURIComponent(email)}`
    const url = `${strUrl}&${params}`

    setFormStatus({ state: "loading", message: "Enviando..." })

    jsonp(
      url,
      {
        param: "c",
      },
      (err, data) => {
        if (err) {
          setFormStatus({
            state: "error",
            message: `Houve um problema ao se cadastrar. Por favor, tente novamente mais tarde.`,
          })
          // In case we need to remove the message
          // setTimeout(function() {
          //   setFormStatus({})
          //   setEmail("")
          // }, 5000)
        } else {
          setFormStatus({
            state: "success",
            message: `Cadastro efetuado com sucesso!\n
            Siga as instruções enviadas em seu e-mail.`,
          })
        }
      }
    )
  }

  return (
    <div className="newsletter-sign">
      <h2>Nossas Atualizações</h2>

      <div className="newsletter-sign-form">
        <p>
          Receba nossas atualizações e acompanhe o Iqara Islam de perto. Acesse
          nosso Telegram:
        </p>

        <a href="https://t.me/iqaraislam" target="_blank">
          Assinar Telegram
        </a>
      </div>
    </div>
  )
}

export default NewsletterBox
