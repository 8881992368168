import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import ImageRenderer from "../components/imageRenderer"
import SEO from "../components/seo"
import NewsletterBox from "../components/newsletterBox"

import "../components/story.css"

import banner from "../images/Banner_Home.png"

const IndexPage = props => {
  const data = props.data

  return (
    <Layout style={{ maxWidth: "1100px" }}>
      <SEO title="Iqara Islam | Sua Fonte de Islam Sunita" />

      <div className="main">
        <Link className="posts-heading-link" to="/categorias/novidades">
          <h2 className="posts-heading">Novidades</h2>
        </Link>
        <div className="posts">
          {data.recentPosts.edges.map(({ node }) => (
            <Link to={node.slug} className="post" key={node.slug}>
              <ImageRenderer imageData={node.imageUrl} />
              <h2 dangerouslySetInnerHTML={{ __html: node.name }}></h2>
            </Link>
          ))}
        </div>

        <div className="posts-see-more-container">
          <Link className="posts-see-more" to="/categorias/novidades">
            VER MAIS >
          </Link>
        </div>

        <Link className="posts-heading-link" to="/categorias/noticias">
          <h2 className="posts-heading">Notícias</h2>
        </Link>
        <div className="posts">
          {data.newsPosts.edges.map(({ node }) => (
            <Link to={node.slug} className="post" key={node.slug}>
              <ImageRenderer imageData={node.imageUrl} />
              <h2 dangerouslySetInnerHTML={{ __html: node.name }}></h2>
            </Link>
          ))}
        </div>

        <div className="posts-see-more-container">
          <Link className="posts-see-more" to="/categorias/noticias">
            VER MAIS >
          </Link>
        </div>

        <div className="banner-home">
          <a href="https://nizam.app/pt" target="_blank" rel="noreferrer">
            <img src={banner} alt="Banner Home - Iqara Islam" />
          </a>
        </div>

        <Link className="posts-heading-link" to="/categorias/mundo-islamico">
          <h2 className="posts-heading">Mundo Islâmico</h2>
        </Link>
        <div className="posts">
          {data.islamPosts.edges.map(({ node }) => (
            <Link to={node.slug} className="post" key={node.slug}>
              <ImageRenderer imageData={node.imageUrl} />
              <h2 dangerouslySetInnerHTML={{ __html: node.name }}></h2>
            </Link>
          ))}
        </div>
        <div className="posts-see-more-container">
          <Link className="posts-see-more" to="/categorias/mundo-islamico">
            VER MAIS >
          </Link>
        </div>

        <div className="mixed-content">
          <div className="top-reading">
            <h2>Mais lidos da semana</h2>

            <div className="top-reading-posts">
              <ul>
                {data.weeklyPopular.posts.slice(0, 5).map(node => (
                  <li key={node.slug}>
                    <Link
                      to={`/${node.slug}`}
                      dangerouslySetInnerHTML={{
                        __html: node.name,
                      }}
                    ></Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="top-reading">
            <h2>Mais lidos do dia</h2>

            <div className="top-reading-posts">
              <ul>
                {data.dailyPopular.posts.slice(0, 5).map(node => (
                  <li key={node.slug}>
                    <Link
                      to={`/${node.slug}`}
                      dangerouslySetInnerHTML={{
                        __html: node.name,
                      }}
                    ></Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <NewsletterBox />
        </div>

        <div className="posts-categorized">
          <div className="posts-categorized-column">
            <Link
              className="posts-heading-link"
              to="/categorias/alcorao-sagrado"
            >
              <h2 className="posts-heading">Alcorão Sagrado</h2>
            </Link>
            <div>
              {data.firstCatPosts.edges.map(({ node }) => (
                <Link to={node.slug} className="post" key={node.slug}>
                  <ImageRenderer imageData={node.imageUrl} />
                  <h2 dangerouslySetInnerHTML={{ __html: node.name }}></h2>
                </Link>
              ))}
            </div>
            <Link
              className="posts-categorized-see-more"
              to="/categorias/alcorao-sagrado"
            >
              VER MAIS >
            </Link>
          </div>

          <div className="posts-categorized-column">
            <Link
              className="posts-heading-link"
              to="/categorias/profeta-muhammad"
            >
              <h2 className="posts-heading">Profeta Muhammad</h2>
            </Link>
            <div>
              {data.secondCatPosts.edges.map(({ node }) => (
                <Link to={node.slug} className="post" key={node.slug}>
                  <ImageRenderer imageData={node.imageUrl} />
                  <h2 dangerouslySetInnerHTML={{ __html: node.name }}></h2>
                </Link>
              ))}
            </div>
            <Link
              className="posts-categorized-see-more"
              to="/categorias/profeta-muhammad"
            >
              VER MAIS >
            </Link>
          </div>

          <div className="posts-categorized-column">
            <Link
              className="posts-heading-link"
              to="/categorias/historia-islamica"
            >
              <h2 className="posts-heading">História Islâmica</h2>
            </Link>
            <div>
              {data.thirdCatPosts.edges.map(({ node }) => (
                <Link to={node.slug} className="post" key={node.slug}>
                  <ImageRenderer imageData={node.imageUrl} />
                  <h2 dangerouslySetInnerHTML={{ __html: node.name }}></h2>
                </Link>
              ))}
            </div>
            <Link
              className="posts-categorized-see-more"
              to="/categorias/historia-islamica"
            >
              VER MAIS >
            </Link>
          </div>

          <div className="posts-categorized-column">
            <Link
              className="posts-heading-link"
              to="/categorias/praticando-o-islam"
            >
              <h2 className="posts-heading">Praticando o Islam</h2>
            </Link>
            <div>
              {data.fourthCatPosts.edges.map(({ node }) => (
                <Link to={node.slug} className="post" key={node.slug}>
                  <ImageRenderer imageData={node.imageUrl} />
                  <h2 dangerouslySetInnerHTML={{ __html: node.name }}></h2>
                </Link>
              ))}
            </div>
            <Link
              className="posts-categorized-see-more"
              to="/categorias/praticando-o-islam"
            >
              VER MAIS >
            </Link>
          </div>
        </div>

        <div className="posts-categorized">
          <div className="posts-categorized-column">
            <Link
              className="posts-heading-link"
              to="/categorias/perguntas-e-respostas"
            >
              <h2 className="posts-heading">Perguntas e Respostas</h2>
            </Link>
            <div>
              {data.fifthCatPosts.edges.map(({ node }) => (
                <Link to={node.slug} className="post" key={node.slug}>
                  <ImageRenderer imageData={node.imageUrl} />
                  <h2 dangerouslySetInnerHTML={{ __html: node.name }}></h2>
                </Link>
              ))}
            </div>
            <Link
              className="posts-categorized-see-more"
              to="/categorias/perguntas-e-respostas"
            >
              VER MAIS >
            </Link>
          </div>

          <div className="posts-categorized-column">
            <Link className="posts-heading-link" to="/categorias/sufismo">
              <h2 className="posts-heading">Sufismo (Tasawwuf)</h2>
            </Link>
            <div>
              {data.sixthCatPosts.edges.map(({ node }) => (
                <Link to={node.slug} className="post" key={node.slug}>
                  <ImageRenderer imageData={node.imageUrl} />
                  <h2 dangerouslySetInnerHTML={{ __html: node.name }}></h2>
                </Link>
              ))}
            </div>
            <Link
              className="posts-categorized-see-more"
              to="/categorias/sufismo"
            >
              VER MAIS >
            </Link>
          </div>

          <div className="posts-categorized-column">
            <Link
              className="posts-heading-link"
              to="/categorias/islam-brasileiro"
            >
              <h2 className="posts-heading">Islam Brasileiro</h2>
            </Link>
            <div>
              {data.seventhCatPosts.edges.map(({ node }) => (
                <Link to={node.slug} className="post" key={node.slug}>
                  <ImageRenderer imageData={node.imageUrl} />
                  <h2 dangerouslySetInnerHTML={{ __html: node.name }}></h2>
                </Link>
              ))}
            </div>
            <Link
              className="posts-categorized-see-more"
              to="/categorias/islam-brasileiro"
            >
              VER MAIS >
            </Link>
          </div>

          <div className="posts-categorized-column">
            <Link
              className="posts-heading-link"
              to="/categorias/desvendando-mitos"
            >
              <h2 className="posts-heading">Desvendando Mitos</h2>
            </Link>
            <div>
              {data.eigthCatPosts.edges.map(({ node }) => (
                <Link to={node.slug} className="post" key={node.slug}>
                  <ImageRenderer imageData={node.imageUrl} />
                  <h2 dangerouslySetInnerHTML={{ __html: node.name }}></h2>
                </Link>
              ))}
            </div>
            <Link
              className="posts-categorized-see-more"
              to="/categorias/desvendando-mitos"
            >
              VER MAIS >
            </Link>
          </div>

          <div className="posts-categorized-column">
            <Link
              className="posts-heading-link"
              to="/categorias/entretenimento"
            >
              <h2 className="posts-heading">Entretenimento</h2>
            </Link>
            <div>
              {data.ninethCatPosts.edges.map(({ node }) => (
                <Link to={node.slug} className="post" key={node.slug}>
                  <ImageRenderer imageData={node.imageUrl} />
                  <h2 dangerouslySetInnerHTML={{ __html: node.name }}></h2>
                </Link>
              ))}
            </div>
            <Link
              className="posts-categorized-see-more"
              to="/categorias/entretenimento"
            >
              VER MAIS >
            </Link>
          </div>

          <div className="posts-categorized-column">
            <Link
              className="posts-heading-link"
              to="/categorias/historias-para-alma"
            >
              <h2 className="posts-heading">Histórias Para Alma</h2>
            </Link>
            <div>
              {data.tenthCatPosts.edges.map(({ node }) => (
                <Link to={node.slug} className="post" key={node.slug}>
                  <ImageRenderer imageData={node.imageUrl} />
                  <h2 dangerouslySetInnerHTML={{ __html: node.name }}></h2>
                </Link>
              ))}
            </div>
            <Link
              className="posts-categorized-see-more"
              to="/categorias/historias-para-alma"
            >
              VER MAIS >
            </Link>
          </div>

          <div className="posts-categorized-column">
            <Link
              className="posts-heading-link"
              to="/categorias/calendario-islamico"
            >
              <h2 className="posts-heading">Calendário Islâmico</h2>
            </Link>
            <div>
              {data.eleventhCatPosts.edges.map(({ node }) => (
                <Link to={node.slug} className="post" key={node.slug}>
                  <ImageRenderer imageData={node.imageUrl} />
                  <h2 dangerouslySetInnerHTML={{ __html: node.name }}></h2>
                </Link>
              ))}
            </div>
            <Link
              className="posts-categorized-see-more"
              to="/categorias/calendario-islamico"
            >
              VER MAIS >
            </Link>
          </div>

          <div className="posts-categorized-column">
            <Link
              className="posts-heading-link"
              to="/categorias/alcorao-em-topicos"
            >
              <h2 className="posts-heading">Alcorão Em Tópicos</h2>
            </Link>
            <div>
              {data.twelventhCatPosts.edges.map(({ node }) => (
                <Link to={node.slug} className="post" key={node.slug}>
                  <ImageRenderer imageData={node.imageUrl} />
                  <h2 dangerouslySetInnerHTML={{ __html: node.name }}></h2>
                </Link>
              ))}
            </div>
            <Link
              className="posts-categorized-see-more"
              to="/categorias/alcorao-em-topicos"
            >
              VER MAIS >
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    recentPosts: allPosts(
      sort: { fields: [date], order: DESC }
      limit: 5
      filter: { category: { slug: { eq: "novidades" } } }
    ) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date
          category {
            name
          }
        }
      }
    }

    newsPosts: allPosts(
      sort: { fields: [date], order: DESC }
      limit: 5
      filter: { category: { slug: { eq: "noticias" } } }
    ) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date
          category {
            name
          }
        }
      }
    }

    islamPosts: allPosts(
      sort: { fields: [createdAt], order: DESC }
      limit: 5
      filter: { category: { slug: { eq: "mundo-islamico" } } }
    ) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date: createdAt(formatString: "D MMMM, YYYY", locale: "pt-BR")
          category {
            name
          }
        }
      }
    }

    firstCatPosts: allPosts(
      sort: { fields: [createdAt], order: DESC }
      limit: 5
      filter: { category: { slug: { eq: "alcorao-sagrado" } } }
    ) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date: createdAt(formatString: "D MMMM, YYYY", locale: "pt-BR")
          category {
            name
          }
        }
      }
    }

    secondCatPosts: allPosts(
      sort: { fields: [createdAt], order: DESC }
      limit: 5
      filter: { category: { slug: { eq: "profeta-muhammad" } } }
    ) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date: createdAt(formatString: "D MMMM, YYYY", locale: "pt-BR")
          category {
            name
          }
        }
      }
    }

    thirdCatPosts: allPosts(
      sort: { fields: [createdAt], order: DESC }
      limit: 5
      filter: { category: { slug: { eq: "historia-islamica" } } }
    ) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date: createdAt(formatString: "D MMMM, YYYY", locale: "pt-BR")
          category {
            name
          }
        }
      }
    }

    fourthCatPosts: allPosts(
      sort: { fields: [createdAt], order: DESC }
      limit: 5
      filter: { category: { slug: { eq: "praticando-o-islam" } } }
    ) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date: createdAt(formatString: "D MMMM, YYYY", locale: "pt-BR")
          category {
            name
          }
        }
      }
    }

    fifthCatPosts: allPosts(
      sort: { fields: [createdAt], order: DESC }
      limit: 5
      filter: { category: { slug: { eq: "perguntas-e-respostas" } } }
    ) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date: createdAt(formatString: "D MMMM, YYYY", locale: "pt-BR")
          category {
            name
          }
        }
      }
    }

    sixthCatPosts: allPosts(
      sort: { fields: [createdAt], order: DESC }
      limit: 5
      filter: { category: { slug: { eq: "sufismo" } } }
    ) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date: createdAt(formatString: "D MMMM, YYYY", locale: "pt-BR")
          category {
            name
          }
        }
      }
    }

    seventhCatPosts: allPosts(
      sort: { fields: [createdAt], order: DESC }
      limit: 5
      filter: { category: { slug: { eq: "islam-brasileiro" } } }
    ) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date: createdAt(formatString: "D MMMM, YYYY", locale: "pt-BR")
          category {
            name
          }
        }
      }
    }

    eigthCatPosts: allPosts(
      sort: { fields: [createdAt], order: DESC }
      limit: 5
      filter: { category: { slug: { eq: "desvendando-mitos" } } }
    ) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date: createdAt(formatString: "D MMMM, YYYY", locale: "pt-BR")
          category {
            name
          }
        }
      }
    }

    ninethCatPosts: allPosts(
      sort: { fields: [createdAt], order: DESC }
      limit: 5
      filter: { category: { slug: { eq: "entretenimento" } } }
    ) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date: createdAt(formatString: "D MMMM, YYYY", locale: "pt-BR")
          category {
            name
          }
        }
      }
    }

    tenthCatPosts: allPosts(
      sort: { fields: [createdAt], order: DESC }
      limit: 5
      filter: { category: { slug: { eq: "historias-para-alma" } } }
    ) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date: createdAt(formatString: "D MMMM, YYYY", locale: "pt-BR")
          category {
            name
          }
        }
      }
    }

    eleventhCatPosts: allPosts(
      sort: { fields: [createdAt], order: DESC }
      limit: 5
      filter: { category: { slug: { eq: "calendario-islamico" } } }
    ) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date: createdAt(formatString: "D MMMM, YYYY", locale: "pt-BR")
          category {
            name
          }
        }
      }
    }

    twelventhCatPosts: allPosts(
      sort: { fields: [createdAt], order: DESC }
      limit: 5
      filter: { category: { slug: { eq: "alcorao-em-topicos" } } }
    ) {
      edges {
        node {
          name
          excerpt
          slug
          imageUrl
          date: createdAt(formatString: "D MMMM, YYYY", locale: "pt-BR")
          category {
            name
          }
        }
      }
    }

    weeklyPopular: monthPopular {
      posts {
        name
        slug
      }
    }

    dailyPopular {
      posts {
        name
        slug
      }
    }

    allStories {
      edges {
        node {
          name
          images {
            image
            link
          }
          imageUrl
        }
      }
    }
  }
`
